<template>
  <div>
    <HeaderUser/>
      <router-view style="background-color: #fff;"></router-view>
    <Footer/>
  </div>
</template>
<script>
import Footer from "@/components/Footer";
import HeaderUser from "@/components/HeaderUser";

export default {
  name: "UserView",
  components: {Footer, HeaderUser},
  data() {
    return {}
  },
  methods: {}
}
</script>

<style scoped>
</style>
