<template>
  <div class="footer ">
    <div class="modal-cover" v-show="isShowModal||isShowPrivateModal"></div>
    <div class="footer-flex-container">
      <div class="footer-flex-item">
        <img src="@/assets/images/IA/img_bottom_logo.png" alt="logo" class="footer-logo ">
      </div>
      <div class="footer-flex-item-2">
        <p>
          한국 재활 임상평가 데이터베이스
        </p>
        <p>
          대표자 : 김수진<br/>
          (55069) 전라북도 전주시 완산구 천잠로 303, 전주대학교 천잠관 410호<br/>
          TEL : 063-220-2226 ㅣEMAIL : veritaspt@gmail.com<br/>
          Copyrights (C)2021 Jeonju University. All Rights Reserved.<br/>
        </p>
      </div>
      <div class="footer-flex-item">
<!--        <router-link to="/terms/service">-->
          <div class="custom-rectangle-button" @click="isShowModal=true">
            서비스 이용약관
          </div>
<!--        </router-link>-->
<!--        <router-link to="/terms/private">-->
          <div class="custom-rectangle-button" @click="isShowPrivateModal=true">
            개인정보 취급방침
          </div>
<!--        </router-link>-->

      </div>

    </div>

    <ServicePageDialog v-show="isShowModal" @callBackModal="callBackModal"/>
    <PrivatePageDialog v-show="isShowPrivateModal" @callBackModal="callBackModal"/>
  </div>
</template>

<script>
import PrivatePageDialog from "@/views/common/terms/PrivatePageDialog";
import ServicePageDialog from "@/views/common/terms/ServicePageDialog";

export default {
  name: "Footer",
  components: {
    ServicePageDialog,
    PrivatePageDialog,
  },
  data(){
    return{
      isShowModal: false,
      isShowPrivateModal : false,
    }
  },
  methods:{
    showCompleteModal() {
      const self = this;
      // const _body = document.getElementsByTagName('body').item(0)
      self.isShowCompleteModal = !self.isShowCompleteModal
      if (self.isShowCompleteModal) {
        // _body.style.overflow = 'hidden';
        window.scrollTo(0, 0);
      } else {
        // _body.style.overflow = 'unset';
      }
    },
    callBackModal: function (index) {
      const self = this;
      if (index === 0) {
        self.isShowModal = false
        self.isShowPrivateModal = false
      }
    },
  }
}
</script>

<style scoped>

.modal-cover {
  background: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
}

.footer {
  background: #32364C;
  height: 211px;
  padding: 57px 109px;
  margin: 0 auto;
  min-width: 1140px;
}

.footer-logo {
  width: 86px;
  height: 37px;
}

.footer p {
  font-size: 12px;
  line-height: 18px;
  color: #ABBACF;
  margin: 0;
}

.footer p:first-child {
  margin-bottom: 12px;
}

.footer-flex-container {
  max-width: 1140px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.footer-flex-item {
  flex-basis: auto;
  flex-grow: 0;
}

.footer-flex-item-2 {
  flex-basis: auto;
  flex-grow: 0;
  margin-left: 106px;
  margin-right: 238px;
}

.custom-rectangle-button {
  width: 140px;
  height: 36px;
  background: #222539;
  letter-spacing: 0.0125em;
  color: #ABBACF;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 36px;
  text-align: center;
  cursor: pointer;
}

.custom-rectangle-button:first-child {
  margin-bottom: 12px;
}
</style>
