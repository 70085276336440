<template>
  <div class="modal-container">
    <div class="modal-header">
      <h5 class="title">
        이용약관
      </h5>
    </div>
    <div class="modal-center">
        <div class="terms-frame">
          <p v-html="getContent(text)" class="text"></p>
        </div>
    </div>
    <div class="modal-footer">
      <button class="primary-blue custom-btn-box btn-short" @click="btnConfirm">닫기</button>
    </div>
  </div>
</template>

<script>


import terms_text from "@/assets/data/terms_text";


export default {
  name: "ServicePageDialog",
  components: {

  },
  props: {
    patientCount: Number,
  },
  data() {
    return {
      text: terms_text.service
    }
  },
  methods: {
    getContent(content) {
      return content.split('\n').join('<br>');
    },
    btnConfirm() {
      const self = this;
      self.$emit('callBackModal', 0)
    }
  },
  mounted() {
  }
}
</script>

<style scoped>

p, button {
  font-size: 14px !important;
}

.modal-container {
  position: absolute;
  width: 580px;
  height: 657px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
}

.modal-header {
  background: #FFFFFF;
  border-radius: 8px 8px 0 0;
  height: 76px;
  padding: 24px 20px;
}

.title {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.0015em;
  color: rgba(24, 24, 25, 0.9);
  margin: 0;
}

.modal-center {
  padding: 23px 20px;
  background: #F8F8FA;
  height: 507px;
  overflow: auto;
}


.icon-input img {
  position: absolute;
  top: 11px;
  left: 8px;
  z-index: 1;
  width: 18px;
  height: 18px;
}

.sub-title {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.001em;
  color: #272833;
  margin-bottom: 10px;
}


.modal-footer {
  background: #FFFFFF;
  border-radius: 0 0 8px 8px;
}

.modal-footer button {
  margin: 0;
}

.modal-footer button:first-child {
  margin-right: 12px;
}

.btn-short {
  width: 84px;
  font-size: 16px;
  line-height: 36px;
  background: #00498A !important;
}


</style>
